<template>
    <!-- <div class="grid grid-rows-5 md:grid-rows-1 gap-6 md:gap-6 md:grid-cols-3 sm:items-stretch w-full"> -->
    <!-- <div class="grid grid-rows-5 md:grid-rows-1 grid-flow-col gap-6 md:gap-3 md:grid-cols-5 sm:items-stretch w-full">
          <box-plan
            v-for="(l, key) in this.response.data"
            :key="key"
            :titulo-plano="l.title"
            :subtitulo-plano="l.subtitle"
            :velocidade-plano="l.speed"
            :velocidade-promocional="l.promotional_speed"
            :preco-plano="l.price"
            :preco-promocional="l.promotional_price"
            :sva-plano="l.sva"
            :id-plano="l.id"
            :tipo-plano="this.typeId"
            :extras-plano="l.extras"
          />

    </div> -->
    <div>
      <div class="glide px-4 py-4">
        <div class="glide__track" data-glide-el="track">
          <ul class="glide__slides">
            <li class="glide__slide">
              <plano-17 />
            </li>
            <li class="glide__slide">
              <plano-18 />
            </li>
            <li class="glide__slide">
              <plano-19 />
            </li>
            <li class="glide__slide">
              <plano-20 />
            </li>
            <li class="glide__slide">
              <plano-21 />
            </li>
          </ul>
        </div>

        <div class="glide__arrows" data-glide-el="controls">
          <button class="glide__arrow glide__arrow--left -left-8" data-glide-dir="<">
            <div class="h-9 w-9 rounded-xl flex justify-center items-center my-auto">
              <BIconCaretLeftFill class="text-left mr-2 text-gray-500 hover:text-main-pink-500  text-2xl duration-300 ease-in-out" />

            </div>
          </button>
          <button class="glide__arrow glide__arrow--right -right-9 " data-glide-dir=">">
            <div class="h-9 w-9 rounded-xl flex justify-center items-center my-auto duration-300 ease-in-out">
              <BIconCaretRightFill class="text-left mr-2 text-gray-500 hover:text-main-pink-500  text-2xl duration-300 ease-in-out" />
            </div>
          </button>
        </div>

      </div>
    </div>
</template>
<style scoped>
@import '../../../node_modules/@glidejs/glide/dist/css/glide.core.min.css';

.glide__arrow {
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  opacity: 1;
  transform: translateY(-50%);
}

</style>
<script>

import Glide from '@glidejs/glide'

import BoxPlan from "@/components/layout/plans/BoxPlanGlide.vue";
import LoadHome from "@/mixins/LoadHome";

import Plano17 from "@/components/layout/plans/novos/Plano17.vue"
import Plano18 from "@/components/layout/plans/novos/Plano18.vue"
import Plano19 from "@/components/layout/plans/novos/Plano19.vue"
import Plano20 from "@/components/layout/plans/novos/Plano20.vue"
import Plano21 from "@/components/layout/plans/novos/Plano21.vue"

export default {
    components: {
        BoxPlan,
        Plano17,
        Plano18,
        Plano19,
        Plano20,
        Plano21,
    },
    data: () => ({
        urlBase: null,
        cityId: null,
        typeId: null,
    }),
  mixins: [LoadHome],
  methods: {
    getUrlBase() {
        this.cityId = this.idCidade;
        this.typeId = this.idTipo;
        this.urlBase = "plan/";
    },
  },
  props: {
    idCidade: Number,
    idTipo: Number,
  },
  created() {
    this.getUrlBase()
  },
  mounted() {
    new Glide('.glide', {
  type: 'carousel',
  startAt: 0,
  perView: 3,
  gap: 32,
  breakpoints: {

    768: {
      gap: 0,
      perView: 1,
    }
  }
}).mount()
  },

}
</script>