<template>
  <div class="home">
    <check-availability v-if="this.typeId == 2" extra-class="bg-gray-700" />
    <check-availability v-else extra-class="bg-main-purple-500" />

    <section id="planos-index" class="my-6 flex justify-center items-center" v-if="this.typeId == 2">
      <div class="p-8 rounded-md w-10/12 md:w-full md:px-16 2xl:w-3/4">
        <home-plans :id-cidade="this.cityId" :id-tipo="this.typeId" />
      </div>
    </section>

    <section id="planos-index" class="my-6 flex justify-center items-center" v-else>
      <div class="p-8 rounded-md w-10/12 2xl:w-2/3">
        <home-plans-flow :id-cidade="this.cityId" :id-tipo="this.typeId" />
      </div>
    </section>
    <box-corp-solutions v-if="this.typeId == 2" />
    <box-nova-mais  v-if="this.typeId != 2" />
    <new-nova-app />
    <home-testimonials />
  </div>
</template>

<script>
import CheckAvailability from "@/components/layout/goods/CheckAvailability.vue";
import BoxNovaMais from "@/components/layout/home/BoxNovaMais.vue";
import BoxIvy from "@/components/layout/home/BoxIvy.vue";
import HomeTestimonials from "@/components/layout/HomeTestimonialsView.vue";

import NewHomePlans from "@/components/layout/NewHomePlans.vue";
import HomePlans from "@/components/layout/HomePlans.vue";

import NewNovaApp from "@/components/layout/home/NewNovaApp.vue";

import CitySelection from "@/mixins/CitySelection";
import HomePlansGlide from '@/components/layout/HomePlansGlide.vue';
import HomePlansFlow from '@/components/layout/HomePlansFlow.vue';
import HomeCarousel from '@/components/Carousel.vue';
import BoxCorpSolutions from '@/components/layout/home/BoxCorpSolutions.vue';

// @ is an alias to /src

export default {
  name: "HomeView",
  components: {
    HomeTestimonials,
    CheckAvailability,
    BoxNovaMais,
    NewNovaApp,
    BoxIvy,
    HomePlansGlide,
    HomePlansFlow,
    HomeCarousel,
    BoxCorpSolutions,
    NewHomePlans,
    HomePlans
  },
  data: () => ({
    cityId: null,
    typeId: null,
  }),
  mixins: [CitySelection],
  methods: {
    getParams() {
      var returnParam = {
        city: this.cityValue.showList.dataCity,
        type: this.cityValue.showList.dataType
      };
      return returnParam;
    }

  },
};
</script>
