import { createApp } from "vue";
import { createPinia } from "pinia";
import { createGtm } from '@gtm-support/vue-gtm';
import { VueMeta } from 'vue-meta'

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { vMaska } from "maska";
import VueCookies from "vue-cookies";

import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import '../node_modules/flowbite-vue/dist/index.css'

import "./style.css";
import {
  BIconFacebook,
  BIconInstagram,
  BIconLinkedin,
  BIconYoutube,
  BIconTelephoneFill,
  BIconFileEarmarkArrowDownFill,
  BIconGeoAltFill,
  BIconStopwatch,
  BIconCaretLeftFill,
  BIconCaretRightFill,
  BIconWhatsapp,
  BIconCheckCircleFill,
  BIconStarHalf,
  BIconStarFill,
} from "bootstrap-icons-vue";

const pinia = createPinia();
const app = createApp(App);
app.config.globalProperties.apiUrl = "https://rnova.com.br/back-rnova/api";
// app.config.globalProperties.apiUrl = "http://br416.teste.website/~rnovac10/back-rnova/api";
// app.config.globalProperties.apiUrl = "https://back-nova.agnaldobaeta.com.br/api";
// app.config.globalProperties.apiUrl = "http://localhost:8000/api";

app.use(VueMeta);
app.use(pinia);
app.use(
  createGtm({
    id: "GTM-59VZTTB",
    vueRouter: router
  })
);
app.use(require("vue-cookies"));
app
  .use(store)
  .directive("maska", vMaska)
  .use(router)
  .use(LoadingPlugin)
  .component("BIconFacebook",BIconFacebook)
  .component("BIconLinkedin",BIconLinkedin)
  .component("BIconYoutube",BIconYoutube)
  .component("BIconInstagram",BIconInstagram)
  .component("BIconTelephoneFill",BIconTelephoneFill)
  .component("BIconFileEarmarkArrowDownFill",BIconFileEarmarkArrowDownFill)
  .component("BIconGeoAltFill",BIconGeoAltFill)
  .component("BIconStopwatch",BIconStopwatch)
  .component("BIconCaretLeftFill",BIconCaretLeftFill)
  .component("BIconCaretRightFill",BIconCaretRightFill)
  .component("BIconWhatsapp",BIconWhatsapp)
  .component("BIconCheckCircleFill",BIconCheckCircleFill)
  .component("BIconStarFill",BIconStarFill)
  .component("BIconStarHalf",BIconStarHalf)
  .mount("#app");
