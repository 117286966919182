<template>
    <div>
        <div class="grid md:grid-cols-3 gap-4 md:gap-6">
            <plano-17 />
            <plano-18 />
            <plano-19 />
            <plano-20 />
            <plano-disney />
        </div>        
    </div>
</template>
<script>
import Plano17 from "@/components/layout/plans/novos/Plano17.vue"
import Plano18 from "@/components/layout/plans/novos/Plano18.vue"
import Plano19 from "@/components/layout/plans/novos/Plano19.vue"
import Plano20 from "@/components/layout/plans/novos/Plano20.vue"
import PlanoDisney from "@/components/layout/plans/novos/PlanoDisney.vue"

export default {
    components: {
        Plano17,
        Plano18,
        Plano19,
        Plano20,
        PlanoDisney,
    },
}
</script>
