<!-- <template>
    <div>
        <div class="grid md:grid-cols-3 gap-4 md:gap-6">
                <box-plan
                    v-for="(l, key) in this.response.data"
                    :key="key"
                    :titulo-plano="l.title"
                    :subtitulo-plano="l.subtitle"
                    :velocidade-plano="l.speed"
                    :velocidade-promocional="l.promotional_speed"
                    :preco-plano="l.price"
                    :preco-promocional="l.promotional_price"
                    :sva-plano="l.sva"
                    :imagem-plano="l.image"
                    :id-plano="l.id"
                    :tipo-plano="this.typeId"
                    :categoria-plano="l.category"
                    :extras-plano="l.extras"
                    :cidade-plano="this.cityId"
                />
            </div>
    </div>
</template>
<script>
import BoxPlan from "@/components/layout/plans/NewBoxPlan.vue"; 
import axios from "axios";

export default {
    components: {
        BoxPlan,
    },
    
    data: () => ({
        urlBase: null,
        cityId: null,
        typeId: null,
        errors: [],
        response: [],
        fullPage: false,        
    }),
  methods: {
    loadList(e) {
      let returnUrl;

      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        backgroundColor: "#FFC000",
        color: "#581E9B",
        opacity: 0.9,
      });

      if (typeof e === "object") {
        returnUrl = e.url;
      } else {
        e ? (returnUrl = e) : (returnUrl = this.apiUrl + "/plan");
      }

      let config = {
        headers: {
          "Content-type": "multipart/form-data",
        },
        params: {
            city: this.cityId,
            type: this.typeId,
            category: 3
        },
        credentials: "include",
      };
      axios
        .get(returnUrl, config)
        .then((response) => {
          this.response = response.data;
        })
        .catch((errors) => {
          this.response = errors;
        })
        .finally(() => {
          loader.hide();
        });
    },

    getUrlBase() {
        this.cityId = this.idCidade;
        this.typeId = this.idTipo;
        this.category = 3,
        this.urlBase = "plan";
    },

    returnOfferMessage() {

      if(this.idCidade > 5 && this.idTipo != 2) {
        let returnDetails = ["*Oferta válida somente para esta localidade."];

        return returnDetails;
      } else {
        return "";
      }

    },
  },
  props: {
    idCidade: Number,
    idTipo: Number,
  },
  mounted() { 
    this.loadList();
  },
  created() {
    this.getUrlBase()
  }

}
</script> -->
<template>
    <div>
        <div class="grid md:grid-cols-3 gap-4 md:gap-6">
            <nova-familia />
            <max-giga />
            <nova-giga />
        </div>        
    </div>
</template>
<script>

import MaxGiga from "@/components/layout/plans/new/MaxGiga.vue"
import NovaGiga from "@/components/layout/plans/new/NovaGiga.vue"
import NovaFamilia from "@/components/layout/plans/new/NovaFamilia.vue"


export default {
  components: {
    MaxGiga,
    NovaGiga,
    NovaFamilia,
  },
}
</script>
