<template>
    <div class="mb-8 sm:mb-0">

      <div class="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow-sm sm:p-8 dark:bg-gray-800 dark:border-gray-700">
        <span class="font-bold text-sm text-main-purple-500 bg-main-yellow-100 -ml-4 px-2 py-1 rounded-full ">Fibra Mais</span>
        <div class="w-full p-0 my-4">
          <img src="@/assets/img/planos/fibra_mais_novo.jpg" class="rounded-2xl" />
        </div>
        <div class="my-4 flex items-center justify-center">
          <span class="text-5xl md:text-8xl font-extrabold tracking-tighter text-main-purple-500">450</span>
          <span class="text-sm md:text-md font-medium text-white bg-main-pink-500 -ml-3 md:-ml-4 px-1.5 md:px-2 py-0.5 md:py-1 rounded-full ">mega</span>
        </div>
        <hr />
        <div class="my-4 font-normal text-gray-500 tracking-tight text-center">
          Ultravelocidade e estabilidade para estudar, trabalhar e se divertir
        </div>
        <hr />
        <div class="flex justify-center items-center md:h-56">
          <ul role="list" class="space-y-3 my-7">
            <li class="flex items-center">
              <BIconCheckCircleFill class="text-main-pink-500" />
              <span class="text-base font-normal leading-tight text-gray-500 ms-3">Taxa de adesão <strong>grátis</strong>;</span>
            </li>
            <li class="flex items-center">
              <BIconCheckCircleFill class="text-main-pink-500" />
              <span class="text-base font-normal leading-tight text-gray-500 ms-3">Taxa de instalação <strong>grátis</strong>;</span>
            </li>
            <li class="flex items-center">
              <BIconCheckCircleFill class="text-main-pink-500" />
              <a href="https://rnova.com.br/nova-sva" target="_blank" class="text-base font-normal leading-tight text-gray-500 ms-3">Nova Mais;</a>
            </li>
            <li class="flex items-center">
              <BIconCheckCircleFill class="text-main-pink-500" />
              <a href="https://rnova.com.br/nova-sva" target="_blank" class="text-base font-normal leading-tight text-gray-500 ms-3">Wi-fi;</a>
            </li>
            <li class="flex items-center">
              <BIconCheckCircleFill class="text-main-pink-500" />
              <a href="https://rnova.com.br/nova-sva" target="_blank" class="text-base font-normal leading-tight text-gray-500 ms-3">Skeelo;</a>
            </li>
          </ul>
        </div>
        <hr />
        <div class="mt-4 flex items-baseline justify-center text-main-pink-500">
          <span class="text-xl md:text-2xl font-semibold">R$</span>
          <span class="text-5xl font-bold tracking-tight">99</span>
          <span class="text-xl md:text-2xltext-2xl font-semibold">,90</span>
        </div>

        <div class="text-center mt-8">
            <router-link
              class="text-main-purple-800 bg-main-yellow-500 hover:bg-main-yellow-300 focus:ring-4 focus:outline-none focus:ring-main-yellow-200 font-extrabold rounded-lg px-4 md:px-5 py-3 md:py-4 text-center mt-5 text-lg md:text-xl"
              :to="{ name: 'Buy', params: { planId: 26 } }" :id="'plan_' + 26" :name="'plan_' + 26"
            >
              Eu quero!
            </router-link>
        </div>

      </div>

    </div>
</template>

<style scoped>

</style>