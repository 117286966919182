<template>
    <!-- <div class="grid grid-rows-5 md:grid-rows-1 gap-6 md:gap-6 md:grid-cols-3 sm:items-stretch w-full"> -->
    <!-- <div class="grid grid-rows-5 md:grid-rows-1 grid-flow-col gap-6 md:gap-3 md:grid-cols-5 sm:items-stretch w-full">
          <box-plan
            v-for="(l, key) in this.response.data"
            :key="key"
            :titulo-plano="l.title"
            :subtitulo-plano="l.subtitle"
            :velocidade-plano="l.speed"
            :velocidade-promocional="l.promotional_speed"
            :preco-plano="l.price"
            :preco-promocional="l.promotional_price"
            :sva-plano="l.sva"
            :id-plano="l.id"
            :tipo-plano="this.typeId"
            :extras-plano="l.extras"
          />

    </div> -->
        <div>
            <!-- Botões das abas -->
            <div class="tab-buttons grid grid-cols-1 md:grid-cols-4 gap-5">
                <button
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :class="['tab-button md:border-b-4 md:border-main-purple-500 px-3 py-2 text-lg font-semibold text-main-pink-500 md:rounded-t-xl ', { active: currentTab === index }]"
                    @click="currentTab = index"
                >
                    {{ tab.title }}
                </button>
            </div>

            <!-- Conteúdo das abas -->
            <div class="tab-content my-8">
                <component :is="tabs[currentTab].component" :id-cidade="this.cityId" />
            </div>

        </div>
</template>
<style scoped>
        .tab-buttons button {
            background-color: inherit;
            cursor: pointer;
            transition: background-color 0.3s;
            border-color: #581E9B;
            border-bottom-color: #581E9B;
        }
        .tab-buttons button:hover {
            background-color: #eeeeee;
        }
        .tab-buttons button.active {
            background-color: #581E9B;
            border-bottom-color: #fc3a69;
            color:#ffffff;
            font-weight: 700;
            border-bottom-width: 8px;
        }
        @media (orientation: portrait) {
            .tab-buttons button {
                background-color: inherit;
                cursor: pointer;
                transition: background-color 0.3s;
                border-color: #ffffff;
                border-bottom-color: #ffffff;
            }
            .tab-buttons button:hover {
                background-color: #eeeeee;
            }
                .tab-buttons button.active {
                background-color: #581E9B;
                border-bottom-color: none;
                color:#ffffff;
                font-weight: 700;
                border-bottom-width: 0;
            }
        }
</style>
<script>

import LoadHome from "@/mixins/LoadHome";

import NovaFamilia from "@/components/layout/plans/novos/NovaFamilia.vue"
import NovaFibra from "@/components/layout/plans/novos/NovaFibra.vue"
import NovaKids from "@/components/layout/plans/novos/NovaKids.vue"
import NovaGamer from "@/components/layout/plans/novos/NovaGamer.vue"
import NovaFibraTeste from "@/components/layout/plans/novos/NovaFibraTeste.vue"

export default {
    components: {
        NovaFamilia,
        NovaFibra,
        NovaKids,
        NovaGamer,
        NovaFibraTeste,
    },
    data: () => ({
        urlBase: null,
        cityId: null,
        typeId: null,
        currentTab: 0, // Índice da aba atual
            tabs: [

                { title: 'Nova Fibra', component: 'NovaFibraTeste', props: true, },
                { title: 'Nova Kids', component: 'NovaKids' },
                { title: 'Nova Família', component: 'NovaFamilia' },
            ],
    }),
  mixins: [LoadHome],
  computed: {
        // Retorna o componente da aba atual
        currentComponent() {
            return this.tabs[this.currentTab].component;
        },
    },
  methods: {
    getUrlBase() {
        this.cityId = this.idCidade;
        this.typeId = this.idTipo;
        this.urlBase = "plan/";
    },
    // Método para mudar a aba atual
    changeTab(index) {
        this.currentTab = index;
    },
  },
  props: {
    idCidade: Number,
    idTipo: Number,
    cacete: String,
  },
  created() {
    this.getUrlBase()
  },

}
</script>