<template>
    <div class="flex justify-center items-center md:h-56">
        <ul role="list" class="space-y-3 my-7">
            <li class="flex items-center">
                <BIconCheckCircleFill class="text-main-pink-500" />
                <span class="text-base font-normal leading-tight text-gray-500 ms-3">Taxa de adesão e instalação <strong>grátis</strong>;</span>
            </li>

            <li class="flex items-center" v-if="idPlano == 32">
                <BIconCheckCircleFill class="text-main-pink-500" />
                <a href="https://rnova.com.br/nova-sva" target="_blank" class="text-base font-extrabold leading-tight text-main-purple-500 ms-3">Kiddle Pass;</a>
            </li>
            
            <li class="flex items-center" v-if="idPlano == 20 || idPlano == 31 || idPlano == 33 || idPlano == 34">
                <BIconCheckCircleFill class="text-main-pink-500" />
                <a href="https://rnova.com.br/nova-sva" target="_blank" class="text-base font-extrabold leading-tight text-main-purple-500 ms-3">Max;</a>
            </li>
            
            <li class="flex items-center" v-if="idPlano == 25 || idPlano == 30" >
                <BIconCheckCircleFill class="text-main-pink-500"/>
                <a href="https://rnova.com.br/nova-sva" target="_blank" class="text-base font-extrabold leading-tight text-main-purple-500 ms-3">Disney+;</a>
            </li>
        
            <li class="flex items-center" v-if="idPlano == 19 || idPlano == 33">
                <BIconCheckCircleFill class="text-main-pink-500" />
                <a href="https://rnova.com.br/nova-sva" target="_blank" class="text-base font-extrabold leading-tight text-main-purple-500 ms-3">Paramount+;</a>
            </li>

            <li class="flex items-center" v-if="idPlano == 18 || idPlano == 23 || idPlano == 27 || idPlano == 28">
                <BIconCheckCircleFill class="text-main-pink-500" />
                <a href="https://rnova.com.br/nova-sva" target="_blank" class="text-base font-extrabold leading-tight text-main-purple-500 ms-3">Deezer;</a>
            </li>
            
            <li class="flex items-center">
                <BIconCheckCircleFill class="text-main-pink-500" />
                <a href="https://rnova.com.br/nova-sva" target="_blank" class="text-base font-normal leading-tight text-gray-500 ms-3">Nova Mais;</a>
            </li>

            <li class="flex items-center" v-if="idPlano == 34 || idPlano == 35">
                <BIconCheckCircleFill class="text-main-pink-500" />
                <a href="https://rnova.com.br/nova-sva" target="_blank" class="text-base font-normal leading-tight text-gray-500 ms-3">Wi-fi 6;</a>
            </li>
            <li class="flex items-center" v-if="idPlano < 34">
                <BIconCheckCircleFill class="text-main-pink-500" />
                <a href="https://rnova.com.br/nova-sva" target="_blank" class="text-base font-normal leading-tight text-gray-500 ms-3">Wi-fi;</a>
            </li>
            <li class="flex items-center">
                <BIconCheckCircleFill class="text-main-pink-500" />
                <a href="https://rnova.com.br/nova-sva" target="_blank" class="text-base font-normal leading-tight text-gray-500 ms-3">Skeelo.</a>
            </li>
        </ul>
    </div>


</template>
<script>
export default {
    name: "BoxSva",
    props: {
        svaPlano: Array,
        idPlano: Number
    },
    data() {
        return {
            testePorra: Array.isArray(this.svaPlano)
        }
    }
}
</script>