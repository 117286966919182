<template>

    <section id="testimonials">
        <div class="px-8 py-8 bg-white md:bg-[url('@/assets/img/pattern_nova_gray.png')] md:bg-auto md:bg-no-repeat md:bg-right">
            <div class="w-full md:w-2/3 text-main-pink-500 mt-4 mx-auto">
              <div>
                <h3 class="text-2xl md:text-3xl xl:text-4xl tracking-tight font-bold text-center my-4">O que nossos clientes dizem?</h3>
              </div>
              <div class="glide_teste px-4 py-4">
                <div class="glide__track" data-glide-el="track">
                  <ul class="glide__slides">
                    <li class="glide__slide">
                        <testimonial-box testimonial-avatar="E" testimonial-name="Erick Bruno" testimonial-id="1">
                          Top, top!<br /> Internet de qualidade<br />Bom atendimento e instalação rápida<br />Tenho 2 pontos um comercial e 1 em casa<br />Nada a reclamar
                        </testimonial-box>
                    </li>
                    <li class="glide__slide">
                        <testimonial-box testimonial-avatar="E" testimonial-name="Adirson Sana" testimonial-id="2">
                            Recentemente, tive a oportunidade de utilizar os serviços da NOVA TELECON e fiquei extremamente satisfeito. Instalação rápida e sem complicações. Estabilidade da conexão e otima. Suporte técnico da empresa, prestativos e atenciosos. Os pacotes oferecidos são um ponto positivo e um preço justo para a qualidade dos serviços prestados. Em suma, estou satisfeito com o serviço prestado pela NOVA TELECON e recomendo a todos que buscam uma conexão de Internet confiável e um atendimento ao cliente excepcional.
                        </testimonial-box>
                    </li>
                    <li class="glide__slide">
                        <testimonial-box testimonial-avatar="E" testimonial-name="Eduardo Dias" testimonial-id="3">
                            Gostaria de parabenizar o atendimento realizado hoje pelo colaborador Marcos Galdino. Ele foi extremamente atencioso e demonstrou total domínio do sistema. Identificou um problema que diversos outros profissionais da Nova Telecom não haviam constatado em minha residência. Há tempos venho abrindo chamados, que sempre resultavam em soluções paliativas, mas o problema retornava. Marcos, ao chegar à minha residência, prontamente identificou que a fibra estava emendada (o que não é permitido, já que fibra óptica não aceita emendas).<br />
                            Parabéns, Marcos Galdino! Trabalho impecável! Aproveito para sugerir à Nova Telecom que reavalie os treinamentos oferecidos (tanto de sistemas quanto para o pessoal técnico). Vivemos em um mundo conectado, onde muitas pessoas dependem de uma conexão estável para trabalhar de casa. Um fornecimento de sinal prejudicado, causado por algo que não deveria acontecer, como uma emenda na fibra, é inaceitável.<br />
                            PARABÉNS AO COLABORADOR MARCOS GALDINO!!!!!!!
                        </testimonial-box>
                    </li>
                    <li class="glide__slide">
                        <testimonial-box testimonial-avatar="K" testimonial-name="Kennedy Patrick" testimonial-id="4">
                          Recomendo muito a internet de fibra! A qualidade é excelente, com velocidade super estável e ótimo desempenho para streaming, trabalho online e até jogos. Além disso, como é fibra óptica, o sinal é menos suscetível a interferências. Vale muito a pena, principalmente se você precisa de conexão rápida e confiável!
                        </testimonial-box>
                    </li>
                  </ul>
                </div>
                <div class="glide__arrows" data-glide-el="controls">
                  <button class="glide__arrow glide__arrow--left -left-8" data-glide-dir="<">
                    <div class="h-9 w-9 rounded-xl flex justify-center items-center my-auto">
                      <BIconCaretLeftFill class="text-left mr-2 text-gray-500 hover:text-main-pink-500  text-2xl duration-300 ease-in-out" />

                    </div>
                  </button>
                  <button class="glide__arrow glide__arrow--right -right-9 " data-glide-dir=">">
                    <div class="h-9 w-9 rounded-xl flex justify-center items-center my-auto duration-300 ease-in-out">
                      <BIconCaretRightFill class="text-left mr-2 text-gray-500 hover:text-main-pink-500  text-2xl duration-300 ease-in-out" />
                    </div>
                  </button>
                </div>
              </div>
              <div>
                <h3 class="text-3xl md:text-4xl 2xl:text-6xl tracking-tight font-extrabold text-center mt-8 text-main-purple-500">EXCELENTE!</h3>
                <div class="my-3 mx-auto text-center">
                  <BIconStarFill class="text-xl xl:text-4xl text-main-yellow-500 mr-4 inline-block" />
                  <BIconStarFill class="text-xl xl:text-4xl text-main-yellow-500 mr-4 inline-block" />
                  <BIconStarFill class="text-xl xl:text-4xl text-main-yellow-500 mr-4 inline-block" />
                  <BIconStarFill class="text-xl xl:text-4xl text-main-yellow-500 mr-4 inline-block" />
                  <BIconStarFill class="text-xl xl:text-4xl text-main-yellow-500 inline-block" />
                </div>
                <h4 class="text-center tracking-tight italic text-xl mb-4">Com base em avaliações de clientes no Google</h4>

              </div>

            </div>
        </div>
    </section>
</template>
<style scoped>
@import '../../../node_modules/@glidejs/glide/dist/css/glide.core.min.css';

</style>
<script>

import Glide from '@glidejs/glide'

import BoxPlan from "@/components/layout/plans/BoxPlanGlide.vue";
import LoadHome from "@/mixins/LoadHome";

import TestimonialBox from "@/components/layout/goods/TestimonialBox.vue"

export default {
    components: {
        TestimonialBox,
        BoxPlan,
    },
    data: () => ({
        urlBase: null,
        cityId: null,
        typeId: null,
    }),
  mixins: [LoadHome],
  methods: {
    getUrlBase() {
        this.cityId = this.idCidade;
        this.typeId = this.idTipo;
        this.urlBase = "plan/";
    },
  },
  props: {
    idCidade: Number,
    idTipo: Number,
  },
  created() {
    this.getUrlBase()
  },
  mounted() {
    new Glide('.glide_teste', {
  type: 'carousel',
  startAt: 0,
  perView: 3,
  gap: 32,
  breakpoints: {

    768: {
      gap: 0,
      perView: 1,
    }
  }
}).mount()
  },

}
</script>