<template>
    <div>
        <div class="grid md:grid-cols-3 gap-4 md:gap-6">
            <plano-20 />
            <plano-22 />
        </div>        
    </div>
</template>
<script>
import Plano18 from "@/components/layout/plans/novos/Plano18.vue"
import Plano20 from "@/components/layout/plans/novos/Plano20.vue"
import Plano22 from "@/components/layout/plans/novos/Plano22.vue"

export default {
components: {
        Plano20,
        Plano22
    },
}
</script>
