<template>
  <section id="nova-app">
    <div
      class="p-8 bg-main-pink-500 flex justify-center content-center items-center md:bg-[url('@/assets/img/pattern_nova_gray_30.png')] md:bg-auto md:bg-no-repeat md:bg-left"
    >
      <div class="w-11/12 md:w-3/4 text-main-purple-500">
        <div
          class="grid grid-cols-1 gap-8 sm:gap-6 sm:grid-cols-2 items-center"
        >
          <div class="order-2 md:order-1">
            <!-- component -->
            <div class="flex justify-center">
              <div>
                <img
                  class="my-3 md:my-8 w-full rounded-full p-2 md:p-5 border-2 md:border-8 border-white"
                  src="@/assets/img/novo_app.jpg"
                />
              </div>
            </div>
          </div>
          <div class="order-1 md:order-2 mt-8 md:mt-0">
            <h1
              class="text-5xl font-black text-main-yellow-500 uppercase tracking-tighter"
            >
              App Nova Telecom!
            </h1>
        <div class="">
          <ul role="list" class="space-y-3 my-7 text-xl md:text-lg">
            <li class="flex items-center">
              <BIconCheckCircleFill class="text-main-purple-500" />
              <span class="leading-tight text-white ms-3">Acompanhe sua <strong>conexão</strong>;</span>
            </li>
            <li class="flex items-center">
              <BIconCheckCircleFill class="text-main-purple-500" />
              <span class="leading-tight text-white ms-3">Acesse sua fatura e retire a <strong>segunda via</strong>;</span>
            </li>
            <li class="flex items-center">
              <BIconCheckCircleFill class="text-main-purple-500" />
              <span class="leading-tight text-white ms-3">Pagamento <strong>online</strong>;</span>
            </li>
            <li class="flex items-center">
              <span class="leading-tight text-main-yellow-500 ms-0 font-extrabold text-4xl mt-5 uppercase">E muito <strong>mais</strong>!</span>
            </li>
          </ul>
        </div>

            <div class="md:flex md:items-center my-4">
                <google-play-button class="mr-4" />
                <app-store-button />
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import GooglePlayButton from "@/components/layout/goods/GooglePlayButton.vue";
import AppStoreButton from "@/components/layout/goods/AppStoreButton.vue";

export default {
  name: "BoxNovaApp",
  components: {
    GooglePlayButton,
    AppStoreButton,
  },

};
</script>
