<template>
  <div>
    <section id="planos-index" class="my-6 flex justify-center items-center" v-if="this.typeId == 2">
      <div class="p-8 rounded-md w-10/12 md:w-full md:px-16 2xl:w-3/4">
        <home-plans :id-cidade="this.cityId" :id-tipo="this.typeId" />
      </div>
    </section>

    <section id="planos-index" class="my-6 flex justify-center items-center" v-else>
      <div class="p-8 rounded-md w-10/12 2xl:w-2/3">
        <home-plans-flow :id-cidade="this.cityId" :id-tipo="this.typeId" />
      </div>
    </section>

    <box-indicacao />

  </div>
</template>
<script>
import BoxIndicacao from "@/components/layout/home/BoxIndicacao.vue";
import CitySelection from "@/mixins/CitySelection";
import HomePlans from '@/components/layout/HomePlans.vue';
import HomePlansFlow from '@/components/layout/HomePlansFlow.vue';

export default {
  name: "HomeView",
  components: {
    BoxIndicacao,
    HomePlans,
    HomePlansFlow
  },
  data: () => ({
    cityId: null,
    typeId: null,
  }),
  mixins: [CitySelection],
  methods: {
    getParams() {
      var returnParam = {
        city: this.cityValue.showList.dataCity,
        type: this.cityValue.showList.dataType
      };
      return returnParam;
    }

  },
};
</script>