<template>
  <div class="mb-8 sm:mb-0" v-if="tipoPlano == 2">
   <!--box plano-->
   <div class="border-enterprise-green-500 border-2 rounded-3xl sm:h-full">

      <div class="plano_empresa">

        <div v-if="velocidadePromocional == 0">
          <h1 class="text-center text-7xl font-black tracking-tight text-enterprise-green-500">
            {{ velocidadePlano }}
          </h1>
          <div class="text-center mt-2 mb-4">
            <span class="bg-enterprise-green-800 text-white px-3 py-2 rounded-xl font-black text-2xl uppercase">mega</span>
          </div>
        </div>

        <div v-if="velocidadePromocional > 0">
          <p class="text-center text-sm text-gray-500 my-4">Compre</p>
          <h1 class="text-center text-4xl font-black tracking-tight text-main-pink-500">
            <span>{{ velocidadePlano }}</span>
            <span class="bg-enterprise-green-800 text-white px-2 py-1 rounded-xl font-black text-sm uppercase mega-promocional">mega</span>
          </h1>
          <p class="text-center text-sm text-gray-500 mt-2">Leve</p>
          <h1 class="text-center text-7xl font-black tracking-tight text-enterprise-green-500" v-if="velocidadePromocional == 0">
            {{ velocidadePlano }}
          </h1>
          <h1 class="text-center text-7xl font-black tracking-tight text-main-pink-500" v-else >
            {{ velocidadePromocional }}
          </h1>

          <div class="text-center mt-2 mb-4">
            <span class="bg-enterprise-green-800 text-white px-3 py-2 rounded-xl font-black text-2xl uppercase">mega</span>
          </div>
        </div>

      </div>

      <div class="rodape_plano">
        <div class="mb-3 p-3 text-main-purple-500 text-center font-black tracking-tighter" v-if="showSpeed">
          <span class="text-2xl">R$</span>
          <span class="text-5xl">{{ this.planMajor }}</span>
          <span class="text-2xl">,{{ this.planCents }}0</span>
        </div>
        <div class="content-center text-center my-3">
          <router-link
            class="mt-2 py-2 px-4 bg-enterprise-green-500 text-white text-lg font-extrabold uppercase rounded-xl shadow-md hover:bg-enterprise-green-800 focus:outline-none focus:ring-2 focus:bg-enterprise-green-800 focus:ring-opacity-75 bottom-4 relative"
            :to="{ name: 'Buy', params: { planId: idPlano } }" :id="'plan_' + this.idPlano" :name="'plan_' + this.idPlano"
          >
            Assine já!
          </router-link>
          <p class="text-sm font-semibold text-enterprise-green-800 tracking-tight leading-4">
            *Consulte condições
          </p>
        </div>        
      </div>

    </div>
    <!--fim box plano-->    
  </div>
  <div class="mb-8 sm:mb-0" v-else>

    <div class="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow-sm sm:p-8 dark:bg-gray-800 dark:border-gray-700">
        <span class="font-bold text-sm text-main-purple-500 bg-main-yellow-100 -ml-4 px-2 py-1 rounded-full ">{{tituloPlano}}</span>
        <div class="w-full p-0 my-4">
          <img src="@/assets/img/planos/fibra_mais_novo.jpg" class="rounded-2xl" />
        </div>
        <div class="my-4 flex items-center justify-center">
          <span class="text-5xl md:text-8xl font-extrabold tracking-tighter text-main-purple-500">{{ velocidadePlano }}</span>
          <span class="text-sm md:text-md font-medium text-white bg-main-pink-500 -ml-3 md:-ml-4 px-1.5 md:px-2 py-0.5 md:py-1 rounded-full ">mega</span>
        </div>
        <hr />
        <div class="my-4 font-normal text-gray-500 tracking-tight text-center">
          {{ subtituloPlano }}
        </div>
        <hr />
            <box-sva :svaPlano="svaPlano" :idPlano="idPlano" />
        <hr />
        <div class="mt-4 flex items-baseline justify-center text-main-pink-500">
          <span class="text-xl md:text-2xl font-semibold">R$</span>
          <span class="text-5xl font-bold tracking-tight">{{ this.planMajor }}</span>
          <span class="text-xl md:text-2xltext-2xl font-semibold" v-if="this.planCents > 0">,{{ this.planCents }}0</span>
          <span class="text-xl md:text-2xltext-2xl font-semibold" v-else>,00</span>
        </div>

        <div class="text-center mt-8">
            <router-link
              class="text-main-purple-800 bg-main-yellow-500 hover:bg-main-yellow-300 focus:ring-4 focus:outline-none focus:ring-main-yellow-200 font-extrabold rounded-lg px-4 md:px-5 py-3 md:py-4 text-center mt-5 text-lg md:text-xl"
              :to="{ name: 'Buy', params: { planId: idPlano } }" :id="'plan_' + this.idPlano" :name="'plan_' + this.idPlano"
            >
              Eu quero!
            </router-link>
        </div>

      </div>    
    
  </div>
</template>
<style scoped>
.info-price {
  min-height:65px;
  display: flex;
    flex-direction: column;
    justify-content:end;
    align-items: center;
}
.btn-neon {
  border:3px solid #fc3a69;
  position: relative;
  cursor: pointer;
  box-shadow: inset 0 0 0.5em 0 #fc3a69, 0 0 0.5em 0 #fc3a69;
}
.txt-neon {
  cursor: pointer;
  text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 20px #fc3a69, 0 0 10px #fc3a69;
}
.btn-neon:hover {
  color: #f5f5f5;
  border:3px solid #f5f5f5;
  transition:0.4s;
}
.txt-neon:hover {
  color:white;
  transition:0.4s;
}

.velocidade_plano {
  @media (min-width: 768px) {
    /* min-height:140px; */
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
  }
}
.plano_empresa {
  @media (min-width: 768px) {
    min-height:135px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.mega-promocional {
  margin-left: -0.5rem;
}
.plano-destaque {
  position:relative;
  margin-top: -1rem;
  padding-left:1rem;
  padding-right:1rem;

}
.plano-destaque > span {
  position:absolute;
  top:50%;
  left:2.5rem;
  right:2.5rem;
  transform:translateY(-50%);

}
</style>
<script>
import BoxSva from '@/components/layout/plans/NewBoxSVA.vue';

export default {
  name: "BoxPlan",
  components: {BoxSva},
  data() {
    return {
      planMajor: this.getPlanValue()[0],
      planCents: this.getPlanValue()[1],
      promoMajor: this.getPromotionalValue()[0],
      promoCents: this.getPromotionalValue()[1],
      destacaPlano: this.idPlano == 18 || this.idPlano == 22,
      showSpeedHeight: this.boxSpeedHeight()
    };
  },
  props: {
    tituloPlano: String,
    subtituloPlano: String,
    extrasPlano: String,
    velocidadePlano: Number,
    velocidadePromocional: Number,
    precoPlano: Number,
    precoPromocional: Number,
    svaPlano: Array,
    idPlano: Number,
    tipoPlano: Number,
    cidadePlano: Number,
    imagemPlano: String,
    categoriaPlano: Number,
  },
  methods: {
    getPlanValue() {
      let preco = this.precoPlano;
      let digits = preco?.toString().split(".");
      return digits;
    },
    boxSpeedHeight() {
      if(this.cidadePlano > 5) {
        return "h-40"
      } else {
        return "h-36"
      }
    },
    getPromotionalValue() {
      let promoPlano = this.precoPromocional;
      if (promoPlano == 0) { promoPlano == 0.00 }
      let digitsPromo = promoPlano?.toString().split(".");
      return digitsPromo;
    },
  },
  created() {
    this.getPlanValue();
  },
};
</script>
