<template>

  <section id="nova-fibra">
      <div class="">
          <div class="w-full text-main-pink-500 mt-4 mx-auto">
            <div class="glide">
              <div class="glide__track" data-glide-el="track">
                <ul class="glide__slides">
                  <li class="glide__slide">
                      <fibra-mais v-if="this.cityId < 6" />
                      <fibra-mais-alt v-else />
                  </li>
                  <li class="glide__slide">
                      <nova-play v-if="this.cityId < 6" />
                      <nova-play-alt v-else />
                  </li>
                  <li class="glide__slide">
                      <nova-diversao />
                  </li>
                  <li class="glide__slide">
                    <nova-disney />
                  </li>
                  <li class="glide__slide">
                    <nova-max />
                  </li>
                </ul>
              </div>
              <div class="glide__arrows" data-glide-el="controls">
                <button class="glide__arrow absolute glide__arrow--left -left-8" data-glide-dir="<">
                  <div class="h-9 w-9 rounded-xl flex justify-center items-center my-auto">
                    <BIconCaretLeftFill class="text-left mr-2 text-gray-500 hover:text-main-pink-500  text-2xl duration-300 ease-in-out" />

                  </div>
                </button>
                <button class="glide__arrow absolute glide__arrow--right -right-9 " data-glide-dir=">">
                  <div class="h-9 w-9 rounded-xl flex justify-center items-center my-auto duration-300 ease-in-out">
                    <BIconCaretRightFill class="text-left mr-2 text-gray-500 hover:text-main-pink-500  text-2xl duration-300 ease-in-out" />
                  </div>
                </button>
              </div>
            </div>

          </div>
      </div>
      <p class="text-sm italic mt-4 text-gray-500">*Oferta válida somente para esta localidade.</p>
  </section>
</template>
<style scoped>
@import '../../../../../node_modules/@glidejs/glide/dist/css/glide.core.min.css';

.glide__arrow {
display: block;
top: 50%;
z-index: 2;
opacity: 1;
transform: translateY(-50%);
}

</style>
<script>

import Glide from '@glidejs/glide'

import LoadHome from "@/mixins/LoadHome";

import FibraMais from "@/components/layout/plans/new/FibraMais.vue"
import FibraMaisAlt from "@/components/layout/plans/new/FibraMaisAlt.vue"
import NovaPlay from "@/components/layout/plans/new/NovaPlay.vue"
import NovaPlayAlt from "@/components/layout/plans/new/NovaPlayAlt.vue"
import NovaDiversao from "@/components/layout/plans/new/NovaDiversao.vue"
import NovaMax from "@/components/layout/plans/new/NovaMax.vue"
import NovaDisney from "@/components/layout/plans/new/NovaDisney.vue"

export default {
  components: {

      FibraMais,
      FibraMaisAlt,
      NovaPlay,
      NovaPlayAlt,
      NovaDiversao,
      NovaMax,
      NovaDisney

  },
  data: () => ({
      urlBase: null,
      cityId: null,
      typeId: null,
  }),
mixins: [LoadHome],
methods: {
  getUrlBase() {
      this.cityId = this.idCidade;
      this.typeId = this.idTipo;
      this.testando = this.cacete;
      this.funciona = this.testar;
      this.urlBase = "plan/";
  },
},
props: {
  idCidade: Number,
  idTipo: Number,
  cacete: String,
  testar: String,
},
created() {
  this.getUrlBase()
},
mounted() {
  new Glide('.glide', {
type: 'slider',
bound: true,
startAt: 0,
perView: 3,
gap: 32,
breakpoints: {

  768: {
    gap: 0,
    perView: 1,
  }
}
}).mount()
},

}
</script>