import axios from "axios";

export default {
  data: () => ({
    errors: [],
    response: [],
    fullPage: false,
  }),
  methods: {
    loadList(e) {
      let returnUrl;

      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        backgroundColor: "#FFC000",
        color: "#581E9B",
        opacity: 0.9,
      });

      if (typeof e === "object") {
        returnUrl = e.url;
      } else {
        e ? (returnUrl = e) : (returnUrl = this.apiUrl + "/plan");
      }

      let config = {
        headers: {
          "Content-type": "multipart/form-data",
        },
        params: {
            city: this.cityId,
            type: this.typeId,
            category: this.categoryId
        },
        credentials: "include",
      };
      axios
        .get(returnUrl, config)
        .then((response) => {
          this.response = response.data;
        })
        .catch((errors) => {
          this.response = errors;
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  mounted() { 
    this.loadList();
  },
};
