<template>
    <div>
        <div class=" shadow-md bg-white border-2 border-gray-50 rounded-md px-6 py-4 cursor-pointer mb-4">
            <div class="flex items-center content-center mb-3">
                <div class="w-10 h-10 rounded-full bg-main-pink-500 justify-center flex mr-2">
                    <span class="text-white font-medium text-xl mx-auto my-auto self-center">{{ testimonialAvatar }}</span>
                </div>
                <div>
                    <span class="text-xl font-extrabold text-main-purple-500 tracking-tighter uppercase">{{ testimonialName }}</span>
                </div>
            </div>
            <hr />
            <div class="my-3 mx-auto text-center">
                <BIconStarFill class="text-xl text-main-yellow-500 mr-1 inline-block" />
                <BIconStarFill class="text-xl text-main-yellow-500 mr-1 inline-block" />
                <BIconStarFill class="text-xl text-main-yellow-500 mr-1 inline-block" />
                <BIconStarFill class="text-xl text-main-yellow-500 mr-1 inline-block" />
                <BIconStarFill class="text-xl text-main-yellow-500 mr-1 inline-block" />
            </div>
            <hr />
            <div class="mt-3">
                <span class=" text-gray-600 my-4 italic tracking-tight line-clamp-4" :id="'testimonial_' + testimonialId" v-if="!isModalVisible"><slot></slot></span>
                <span class=" text-gray-600 my-4 italic tracking-tight" :id="'testimonial_' + testimonialId" v-else><slot></slot></span>
                
                <button @click="onToggle" class="font-bold hover:font-black md:p-2 lg:p-3 xl:p-4 py-2 block hover:text-main-purple-300">Leia +</button>
            </div>
        </div>
    </div>
    
</template>
<script>
import { BIconStarFill } from 'bootstrap-icons-vue';

export default {
  props: {
    testimonialAvatar: String,
    testimonialName: String,
    testimonialId: Number,
  },
  data() {
    return {
        isActive: false,
        isOpen: false,
    }
  },
  
  computed: {
    isModalVisible() {
      return this.isOpen;
    }
  },
  methods: {
    activate() {
        var getTestimonial = document.getElementById("testimonial_" + this.testimonialId);

        if(getTestimonial.classList.contains("line-clamp-6")) {
            getTestimonial.classList.add("line-clamp-none");
            getTestimonial.classList.remove("line-clamp-6");
        } else{
            getTestimonial.classList.remove("line-clamp-none");
            getTestimonial.classList.add("line-clamp-6");
        }
    },
    onToggle() {
      this.isOpen = !this.isOpen;
    }
  }
  
};
</script>
<style>

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease-out;
}
</style>